exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-code-in-place-js": () => import("./../../../src/pages/code-in-place.js" /* webpackChunkName: "component---src-pages-code-in-place-js" */),
  "component---src-pages-election-promises-js": () => import("./../../../src/pages/election-promises.js" /* webpackChunkName: "component---src-pages-election-promises-js" */),
  "component---src-pages-experiments-js": () => import("./../../../src/pages/experiments.js" /* webpackChunkName: "component---src-pages-experiments-js" */),
  "component---src-pages-gccvac-js": () => import("./../../../src/pages/gccvac.js" /* webpackChunkName: "component---src-pages-gccvac-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-work-js": () => import("./../../../src/pages/not-work.js" /* webpackChunkName: "component---src-pages-not-work-js" */),
  "component---src-pages-o-dinos-js": () => import("./../../../src/pages/o-dinos.js" /* webpackChunkName: "component---src-pages-o-dinos-js" */),
  "component---src-pages-park-of-joy-js": () => import("./../../../src/pages/park-of-joy.js" /* webpackChunkName: "component---src-pages-park-of-joy-js" */),
  "component---src-pages-tales-for-tweens-js": () => import("./../../../src/pages/tales-for-tweens.js" /* webpackChunkName: "component---src-pages-tales-for-tweens-js" */),
  "component---src-pages-tapit-js": () => import("./../../../src/pages/tapit.js" /* webpackChunkName: "component---src-pages-tapit-js" */),
  "component---src-pages-yt-notion-book-js": () => import("./../../../src/pages/yt-notion-book.js" /* webpackChunkName: "component---src-pages-yt-notion-book-js" */)
}

